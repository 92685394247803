import React from "react";
import "./testimonials.scss";

const Testimonials = ({ title, testimonials }) => {
    if (typeof testimonials === "undefined" || testimonials.length <= 0) return null;
    const testimonialRows = testimonials.map((testimonial, index) => (
        <div key={index} className="col-6 col-lg-4">
            <div className="testimonials__testimonial text-center">
                <p className="testimonials__quote">{testimonial.quote}</p>
                <p className="testimonials__name">{testimonial.name}</p>
            </div>
        </div>
    ));
    return (
        <section id="testimonials" className="testimonials">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className="text-center">{title}</h2>
                    </div>
                </div>
                <div className="row">
                    {testimonialRows}
                </div>
            </div>
        </section>
    );
}

export default Testimonials
