import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet";
import Layout from "../Components/layout";
import Hero from "../Components/hero";
import About from "../Components/about";
import Services from "../Components/services";
import Gallery from "../Components/gallery";
import Testimonials from "../Components/testimonials";
import Contact from "../Components/contact";

export function HomePage({ homepage, settings, galleryImages, serviceImages }) {
  return (
    <div>
      <Helmet>
        <meta name="description" content={homepage.metaDescription} />
      </Helmet>
      <Hero title={homepage.heroTitle} copy={homepage.heroCopy} image={homepage.heroImage} imageDesktop={homepage.heroImageDesktop} />
      <About title={homepage.aboutTitle} copy={homepage.aboutCopy} image={homepage.aboutImage} />
      <Services title={homepage.servicesTitle} services={homepage.services} images={serviceImages} />
      <Gallery title={homepage.galleryTitle} galleryImages={homepage.galleryImages} thumbnails={galleryImages} />
      <Testimonials title={homepage.testimonialsTitle} testimonials={homepage.testimonials} />
      <Contact title={homepage.contactTitle} copy={homepage.contactCopy} settings={settings} />
    </div>
  )
};

export default function Template({ data }) {
  const frontmatter = data.data.frontmatter;
  const galleryImages = data.galleryImages.edges.map((item) => ({
    relativePath: item.node.relativePath,
    thumbnail: item.node.childImageSharp.fixed.src
  }));
  const serviceImages = data.serviceImages.edges.map((item) => ({
    relativePath: item.node.relativePath,
    url: item.node.childImageSharp.fixed.src
  }));
  return (
    <Layout>
      <HomePage homepage={frontmatter} settings={data.settings.frontmatter} galleryImages={galleryImages} serviceImages={serviceImages} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    data: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        metaDescription
        heroTitle
        heroCopy
        heroImageDesktop
        heroImage
        aboutTitle
        aboutCopy
        aboutImage
        servicesTitle
        services {
          title
          image
          copy
        }
        galleryTitle
        galleryImages {
          title
          image
        }
        testimonialsTitle
        testimonials {
          quote
          name
        }
        contactTitle
        contactCopy
      }
    }
    settings: markdownRemark(fileAbsolutePath: { regex: "/settings/settings.md/" }) {
      frontmatter {
        logo
        copyright
        phone
        email
        instagramUrl
        instagramLabel
        facebookUrl
        facebookLabel
      }
    },
    galleryImages: allFile(filter: { extension: { in: ["jpg", "jpeg", "png"] }}) {
      edges {
        node {
          childImageSharp {
            fixed(fit: COVER, width: 350, height: 350, cropFocus: CENTER) {
              src
            }
          }
          relativePath
        }
      }
    },
    serviceImages: allFile(filter: { extension: { in: ["jpg", "jpeg", "png"] }}) {
      edges {
        node {
          childImageSharp {
            fixed(fit: COVER, width: 570, height: 570, cropFocus: CENTER) {
              src
            }
          }
          relativePath
        }
      }
    }
  }
`