import React from "react";
import "./hero.scss";

const Hero = ({ title, copy, image, imageDesktop }) => {
    const copyElement = copy !== "" ? (<p>{copy}</p>) : "";
    return (
        <div id="hero-0l54b7" className="hero d-flex align-items-center" style={{backgroundImage: `url(${image})`}}>
            <style>@media screen and (min-width: 992px) {`{ #hero-0l54b7 { background-image: url(${imageDesktop}) !important; } }`}</style>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-8 col-xl-6">
                        <h1>{title}</h1>
                        {copyElement}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hero
