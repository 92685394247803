import React from "react";
import { Helmet } from "react-helmet";

const Metadata = ({ title }) => (
    <Helmet>
        <title>{title}</title>
        <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-touch-icon.png?v=alJgQXj2Q6" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png?v=alJgQXj2Q6" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png?v=alJgQXj2Q6" />
        <link rel="manifest" href="/favicons/site.webmanifest?v=alJgQXj2Q6" />
        <link rel="mask-icon" href="/favicons/safari-pinned-tab.svg?v=alJgQXj2Q6" color="#136433" />
        <link rel="shortcut icon" href="/favicons/favicon.ico?v=alJgQXj2Q6" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-config" content="/favicons/browserconfig.xml?v=alJgQXj2Q6" />
        <meta name="theme-color" content="#ffffff"></meta>
    </Helmet>
);

export default Metadata;