import React from "react";
import "./services.scss";
import showdown from "showdown";

const Services = ({ title, services, images }) => {
    if (typeof services === "undefined" || services.length <= 0) return null;
    const converter = new showdown.Converter();
    const serviceRows = services.map((service, index) => {
        let matchingImage = images.filter((image) => {
            let imagePathArray = service.image.split('/');
            return (imagePathArray[imagePathArray.length - 1] === image.relativePath);
        })[0];
        if (typeof matchingImage === "undefined") {
            matchingImage = {
                url: service.image
            };
        }
        return (
            <div key={index} className={`row align-items-center${((index + 1) >= services.length ? "" : " mb-5")}`}>
                <div className="col-12 col-lg-8 order-1 order-lg-0">
                    <div className="services__content">
                        <h3>{service.title}</h3>
                        <div className="services__copy" dangerouslySetInnerHTML={{ __html: converter.makeHtml(service.copy) }} />
                    </div>
                </div>
                <div className="col-12 col-lg-3 offset-lg-1 order-0 order-lg-1 text-center text-lg-right">
                    <img src={matchingImage.url} alt={service.title} className="img-fluid w-100 mb-4 mb-lg-0" />
                </div>
            </div>
        );
    });
    return (
        <section id="services" className="services">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className="text-center">{title}</h2>
                    </div>
                </div>
                {serviceRows}
            </div>
        </section>
    );
}

export default Services
