import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Metadata from "./metadata";
import Header from "./header";
import Footer from "./footer";

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
        query LayoutQuery {
            settings: markdownRemark(fileAbsolutePath: { regex: "/settings/settings.md/" }) {
                frontmatter {
                    logo
                    copyright
                }
            }
            navigation: markdownRemark(frontmatter: { slug: { eq: "/" }}) {
                frontmatter {
                    aboutTitle
                    servicesTitle
                    galleryTitle
                    testimonialsTitle
                    contactTitle
                }
            }
        }
    `);
    return (
        <div>
            <Metadata title="Lucy Walsh Veterinary Physiotherapy" />
            <Header logo={data.settings.frontmatter.logo} navigation={data.navigation.frontmatter} />
            {children}
            <Footer copyright={data.settings.frontmatter.copyright} />
        </div>
    );
}

export default Layout;