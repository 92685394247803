import React from "react";
import "./contact.scss";
import showdown from "showdown";

const Contact = ({ title, copy, settings }) => {
    const converter = new showdown.Converter();
    const phoneElement = (settings.phone === "" || settings.phone === null) ? null : (<a href={`tel:${settings.phone}`}>
        <i className="contact__icon contact__icon--phone"></i>
        {settings.phone}
    </a>);
    const emailElement = (settings.email === "" || settings.email === null) ? null : (<a href={`mailto:${settings.email}`}>
        <i className="contact__icon contact__icon--email"></i>
        {settings.email}
    </a>);
    const instagramElement = (settings.instagramLabel === "" || settings.instagramLabel === null || settings.instagramUrl === "" || settings.instagramUrl === null) ? null : (<a href={`mailto:${settings.instagramUrl}`}>
        <i className="contact__icon contact__icon--instagram"></i>
        {settings.instagramLabel}
    </a>);
    const facebookElement = (settings.facebookLabel === "" || settings.facebookLabel === null || settings.facebookUrl === "" || settings.facebookUrl === null) ? null : (<a href={`mailto:${settings.facebookUrl}`}>
        <i className="contact__icon contact__icon--facebook"></i>
        {settings.facebookLabel}
    </a>);
    return (
        <section id="contact" className="contact">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-8 offset-lg-2 text-center">
                        <h2>{title}</h2>
                        <p dangerouslySetInnerHTML={{ __html: converter.makeHtml(copy) }} />
                    </div>
                    <div className="col-12">
                        <div className="contact__methods d-lg-flex justify-content-lg-center flex-lg-wrap">
                            {phoneElement}
                            {emailElement}
                            {instagramElement}
                            {facebookElement}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact
