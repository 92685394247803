import React, { useState } from "react";
import "./gallery.scss";

const Gallery = ({ title, galleryImages, thumbnails }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState(null);
    if (typeof galleryImages === "undefined" || galleryImages.length <= 0) return null;
    const handleClick = (e) => {
        e.preventDefault();
        setModalImage({
            image: e.target.getAttribute('data-image'),
            title: e.target.alt
        });
        setModalOpen(true);
    };
    const galleryImageRows = galleryImages.map((galleryImage, index) => {
        let matchingThumbnail = thumbnails.filter((thumbnail) => {
            let imagePathArray = galleryImage.image.split('/');
            return (imagePathArray[imagePathArray.length - 1] === thumbnail.relativePath);
        })[0];
        if (typeof matchingThumbnail === "undefined") {
            matchingThumbnail = {
                thumbnail: galleryImage.image
            };
        }
        return (
            <div key={index} className="col-6 col-lg-4">
                <div className="gallery__imageWrap">
                    <img src={matchingThumbnail.thumbnail} alt={galleryImage.title} data-image={galleryImage.image} className="gallery__image img-fluid w-100" onClick={handleClick} />
                </div>
            </div>
        );
    });
    return (
        <section id="gallery" className="gallery">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className="text-center">{title}</h2>
                    </div>
                </div>
                <div className="row">
                    {galleryImageRows}
                </div>
            </div>
            <div className={(modalOpen ? "modal fade show d-block" : "modal")} tabIndex={(modalOpen ? "0" : "-1")}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-body">
                        <div className="gallery__modalBody text-center d-block">
                            <div className="clearfix">
                                <button type="button" className="gallery__modalClose close" data-dismiss="modal" aria-label="Close" onClick={() => setModalOpen(false)}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {(modalImage === null ? null : (<img src={modalImage.image} alt={modalImage.title} className="gallery__modalImage" />))}
                        </div>
                    </div>
                </div>
            </div>
            <div className={(modalOpen ? "modal-backdrop fade show" : "modal-backdrop d-none")} />
        </section>
    );
}

export default Gallery
