import React from "react";
import "./footer.scss";

const Footer = ({ copyright }) => (
	<footer className="footer">
		<div className="container">
			<div className="row">
				<div className="col-12">
					<p>{copyright}</p>
				</div>
			</div>
		</div>
	</footer>
)

export default Footer
