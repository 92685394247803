import React from "react";
import "./about.scss";
import showdown from "showdown";

const About = ({ title, copy, image }) => {
    const converter = new showdown.Converter();
    return (
        <section id="about" className="about">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-0">
                        <img src={image} alt={title} className="img-fluid w-100 mb-4 mb-lg-0" />
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-0">
                        <h2>{title}</h2>
                        <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(copy) }} />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About
