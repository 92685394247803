import React from "react";
import "./header.scss";

const Header = ({ logo, navigation }) => {
    const doScrolling = (elementY, duration) => { 
        const startingY = window.pageYOffset;
        const diff = elementY - startingY;
        let start;
        let step = (timestamp) => {
            if (!start) start = timestamp;
            const time = timestamp - start;
            const percent = Math.min(time / duration, 1);
            window.scrollTo(0, startingY + diff * percent);
            if (time < duration) window.requestAnimationFrame(step);
        };
        window.requestAnimationFrame(step);
    };
    const handleNavigationClick = (e) => {
        e.preventDefault();
        const targetSection = e.target.href.split('#')[1];
        doScrolling(document.getElementById(targetSection).offsetTop, 250);
        const $state = document.getElementById('header__menuState');
        if ($state.checked) $state.click();
    };
    return (
        <header className="header">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-auto">
                        <div className="d-flex justify-content-center flex-wrap align-items-center">
                            <a href="/" className="header__logo" style={{backgroundImage: `url(${logo})`}}>
                                <span className="sr-only">Home</span>
                            </a>
                            <input className="header__menuState d-none" type="checkbox" id="header__menuState" />
                            <label htmlFor="header__menuState" className="header__hamburger d-lg-none">
                                <i></i>
                                <span className="sr-only">Menu</span>
                            </label>
                            <ul className="header__nav">
                                <li className="header__listItem">
                                    <a href="#about" className="header__link" onClick={handleNavigationClick}>{navigation.aboutTitle}</a>
                                </li>
                                <li className="header__listItem">
                                    <a href="#services" className="header__link" onClick={handleNavigationClick}>{navigation.servicesTitle}</a>
                                </li>
                                <li className="header__listItem">
                                    <a href="#gallery" className="header__link" onClick={handleNavigationClick}>{navigation.galleryTitle}</a>
                                </li>
                                <li className="header__listItem">
                                    <a href="#testimonials" className="header__link" onClick={handleNavigationClick}>{navigation.testimonialsTitle}</a>
                                </li>
                                <li className="header__listItem">
                                    <a href="#contact" className="header__link" onClick={handleNavigationClick}>{navigation.contactTitle}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header
